<template>
  <div class="p-mt-2">
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <div class="p-field p-grid">
      <div class="p-col-12 p-md-2 p-mb-2">
        <Button
          label="Marcar Férias"
          icon="pi pi-plus"
          class="p-button-success p-button-outlined"
          style="width: 100%"
          @click="addVacations"
        />
      </div>
    </div>
    <DataTable
      editMode="row"
      :value="vacationsList"
      class="p-datatable-sm"
      responsiveLayout="scroll"
    >
      <Column field="start" header="Data de Início">
        <template #body="slotProps">
          <span class="p-column-title">Data Início:</span>
          {{ slotProps.data.start }}
        </template>
      </Column>
      <Column field="end" header="Data de Fim">
        <template #body="slotProps">
          <span class="p-column-title">Data Fim:</span>
          {{ slotProps.data.end }}
        </template>
      </Column>
      <Column field="number_days" header="Dias">
        <template #body="slotProps">
          <span class="p-column-title">Dias:</span>
          {{ slotProps.data.number_days }}
        </template>
      </Column>
      <Column field="approved" header="Aprovado">
        <template #body="slotProps">
          <span class="p-column-title">Aprovado:</span>
          {{ slotProps.data.approved == true ? "Sim" : "Não" }}
        </template>
      </Column>
      <Column field="observations" header="Observações">
        <template #body="slotProps">
          <span class="p-column-title">Observações:</span>
          {{ slotProps.data.observations }}
        </template>
      </Column>
      <Column>
        <template #body="slotProps">
          <Button
            v-if="!slotProps.data.approved"
            icon="pi pi-pencil"
            class="p-button-rounded p-button-success p-button-outlined p-mr-2"
            @click="editVacation(slotProps.data)"
          />
          <Button
            v-if="!slotProps.data.approved"
            icon="pi pi-trash"
            class="p-button-rounded p-button-danger p-button-outlined"
            @click="confirmDeleteVacation(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>
    <Dialog
      :visible.sync="deleteVacationDialog"
      :position="$isMobile() ? '' : 'top'"
      :style="{ width: $isMobile() ? '100vw' : '30vw' }"
      header="Cancelar Férias"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="vacation">
          <span>
            Tem a certeza que pretende cancelar
            <u>{{ vacation.number_days }} dias</u> de férias, com
            <u>início no dia {{ vacation.start }}</u> e
            <u>fim no dia {{ vacation.end }}</u
            >?
          </span>
        </span>
      </div>
      <template #footer>
        <Button
          label="Sim"
          icon="pi pi-check"
          class="p-button-text p-button-success"
          @click="deleteVacation"
        />
        <Button
          label="Não"
          icon="pi pi-times"
          class="p-button-text p-button-danger"
          @click="deleteVacationDialog = false"
        />
      </template>
    </Dialog>

    <Dialog
      :visible.sync="vacationDialog"
      :style="{ width: $isMobile() ? '100vw' : '40vw' }"
      :header="vacation.action == 'create' ? 'Marcar Férias' : 'Editar Férias'"
      :modal="true"
      :contentStyle="{ overflow: 'visible' }"
    >
      <form
        name="vacationsForm"
        class="p-col-12"
        @submit.prevent="saveVacation"
      >
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-6 p-mt-2">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <i class="pi pi-calendar"></i>
              </span>
              <span class="p-float-label">
                <v-date-picker
                  id="start"
                  name="start"
                  v-model="vacation.start"
                  :min-date="new Date()"
                  v-validate="'required'"
                  :masks="{
                    input: 'DD-MM-YYYY',
                  }"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <span class="p-float-label">
                      <input
                        v-bind:class="[
                          { 'p-error': errors.has('start') },
                          'form-control',
                          'p-inputtext',
                          'p-component',
                          'p-filled',
                        ]"
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                      <label for="inputtext">Início</label>
                    </span>
                  </template>
                </v-date-picker>
              </span>
              <small v-if="errors.has('start')" class="p-error" role="alert"
                >Início é obrigatório</small
              >
            </div>
          </div>
          <div class="p-field p-col-12 p-md-6 p-mt-2">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <i class="pi pi-calendar"></i>
              </span>
              <span class="p-float-label">
                <v-date-picker
                  id="end"
                  name="end"
                  v-model="vacation.end"
                  :min-date="
                    vacation.start ? new Date(vacation.start) : new Date()
                  "
                  v-validate="'required'"
                  :masks="{
                    input: 'DD-MM-YYYY',
                  }"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <span class="p-float-label">
                      <input
                        v-bind:class="[
                          { 'p-error': errors.has('end') },
                          'form-control',
                          'p-inputtext',
                          'p-component',
                          'p-filled',
                        ]"
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                      <label for="inputtext">Fim</label>
                    </span>
                  </template>
                </v-date-picker>
              </span>
              <small v-if="errors.has('end')" class="p-error" role="alert"
                >Fim é obrigatório</small
              >
              <small
                v-if="this.vacation.end < this.vacation.start"
                class="p-error"
                role="alert"
                >Fim é antes do Início</small
              >
            </div>
          </div>
          <div class="p-field p-col-12 p-md-12" style="margin-top: 10px">
            <span class="p-float-label">
              <Textarea
                id="obsevations"
                v-model="vacation.comments"
                :autoResize="true"
                v-bind:class="['p-inputtextarea', 'p-inputtext', 'p-component']"
                name="obsevations"
                rows="3"
              />
              <label for="inputtext">Observações</label>
            </span>
          </div>
        </div>
      </form>

      <template #footer>
        <Button
          :label="
            vacation.action == 'create' ? 'Marcar Férias' : 'Editar Férias'
          "
          icon="pi pi-check"
          class="p-button-text p-button-success"
          @click="saveVacation"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-text p-button-danger"
          @click="createUpdateCanceled"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import vacationsService from "../services/vacations.service";
import VacationsModel from "../models/vacations";
import { getOnlyDate } from "../helpers/helpers";
export default {
  name: "VacationsList",
  props: ["vacationsStatistics", "selectedYear"],
  watch: {
    selectedYear: {
      deep: true,
      immediate: true,
      handler() {
        this.getVacationsList().then(() => this.updateStatistics());
      },
    },
  },
  data() {
    return {
      vacationsList: [],
      vacation: {},
      deleteVacationDialog: false,
      vacationDialog: false,
      inEdit: null,
    };
  },
  created() {
    // this.getVacationsList().then(() => this.updateStatistics());
  },
  methods: {
    getVacationsList() {
      return vacationsService
        .getVacationsByYearList(
          this.$store.state.auth.user.id,
          this.selectedYear
        )
        .then((response) => (this.vacationsList = response));
    },
    confirmDeleteVacation(vacation) {
      this.vacation = vacation;
      this.deleteVacationDialog = true;
    },
    deleteVacation() {
      let start = this.vacation.start;
      let end = this.vacation.end;

      const areYou = (vacationAux) => {
        return vacationAux.start == start && vacationAux.end == end;
      };
      this.deleteVacationDialog = false;
      this.vacation = {};
      vacationsService
        .deleteVacation(this.$store.state.auth.user.id, start, end)
        .then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro",
              detail: "Ocorreu um erro ao cancelar as férias",
              life: 3000,
            });
          }

          let vacationIndex = this.vacationsList.findIndex(areYou);

          if (vacationIndex >= 0) {
            this.vacationsList.splice(vacationIndex, 1);
          }
          return this.$toast.add({
            severity: "success",
            summary: "Férias canceladas",
            detail: "As férias foram canceladas com sucesso",
            life: 3000,
          });
        })
        .then(() => this.updateStatistics());
    },
    addVacations() {
      this.vacation = new VacationsModel("create");
      this.vacationDialog = true;
    },
    saveVacation() {
      this.$validator.validateAll().then((isValid) => {
        if (
          !isValid ||
          (this.vacation.end != undefined &&
            this.vacation.start &&
            this.vacation.end < this.vacation.start)
        ) {
          return;
        }
        this.$validator.pause();
        if (this.vacation.action === "create") {
          return this.saveNewVacation();
        } else if (this.vacation.action === "update") {
          return this.updateVacation();
        }
      });
    },
    createUpdateCanceled() {
      this.vacation = {};
      this.vacationDialog = false;
      this.inEdit = null;
    },
    editVacation(vacation) {
      const areYou = (vacationAux) => {
        return (
          vacationAux.start == vacation.start && vacationAux.end == vacation.end
        );
      };
      this.inEdit = this.vacationsList.findIndex(areYou);

      this.vacation = new VacationsModel(
        "update",
        new Date(vacation.start),
        new Date(vacation.end),
        vacation.observations
      );
      this.vacationDialog = true;
    },
    saveNewVacation() {
      vacationsService
        .createVacation(
          this.$store.state.auth.user.id,
          getOnlyDate(this.vacation.start),
          getOnlyDate(this.vacation.end),
          this.selectedYear
        )
        .then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro",
              detail: "Ocorreu um erro ao criar as férias",
              life: 3000,
            });
          }
          this.vacationsList.push({
            start: response.start,
            end: response.end,
            number_days: response.number_days,
            approved: false,
            observations: this.vacation.comments,
          });
          this.vacationDialog = false;
          this.vacation = {};
          return this.$toast.add({
            severity: "success",
            summary: "Férias marcadas",
            detail: "As férias foram marcadas com sucesso",
            life: 3000,
          });
        })
        .then(() => this.updateStatistics());
    },
    updateVacation() {
      let updateVacation = this.vacationsList[this.inEdit];
      vacationsService
        .updateVacation(
          this.$store.state.auth.user.id,
          updateVacation.start,
          updateVacation.end,
          getOnlyDate(this.vacation.start),
          getOnlyDate(this.vacation.end)
        )
        .then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro",
              detail: "Ocorreu um erro ao alterar as férias",
              life: 3000,
            });
          }
          this.vacationsList[this.inEdit].start = response.start;
          this.vacationsList[this.inEdit].end = response.end;
          this.vacationsList[this.inEdit].number_days = response.number_days;
          this.vacationsList[this.inEdit].observations = this.vacation.comments;
          this.vacationDialog = false;
          this.inEdit = null;
          this.vacation = {};

          return this.$toast.add({
            severity: "success",
            summary: "Férias alteradas",
            detail: "As férias foram alteradas com sucesso",
            life: 3000,
          });
        })
        .then(() => this.updateStatistics());
    },
    updateStatistics() {
      this.vacationsStatistics.marked = 0;
      this.vacationsStatistics.aproved = 0;
      this.vacationsStatistics.unapproved = 0;
      this.vacationsList.forEach((vacation) => {
        this.vacationsStatistics.marked += vacation.number_days;

        if (vacation.approved) {
          this.vacationsStatistics.aproved += vacation.number_days;
        } else {
          this.vacationsStatistics.unapproved += vacation.number_days;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.p-datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

.p-column-title {
  padding: 0.4rem;
  min-width: 30%;
  display: inline-block;
  margin: -0.4rem;
  font-weight: bold;
}
</style>
