<template>
  <div class="card p-shadow-6">
    <h1>Marcação de Férias</h1>
    <div class="p-grid p-fluid dashboard p-jc-center">
      <div class="p-col-12 p-lg-2">
        <div class="card summary p-shadow-8">
          <span
            class="title"
            v-tooltip="'Limite de dias de férias para marcar'"
          >
            Total
          </span>
          <span class="count vacations-total">
            {{ vacationsStatistics.authorized }}
          </span>
        </div>
      </div>
      <div class="p-col-12 p-lg-2">
        <div class="card summary p-shadow-8">
          <span class="title" v-tooltip="'Total de dias de férias já marcados'">
            Marcados
          </span>
          <span class="count vacations-marked">
            {{ vacationsStatistics.marked }}
          </span>
        </div>
      </div>
      <div class="p-col-12 p-lg-2">
        <div class="card summary p-shadow-8">
          <span
            class="title"
            v-tooltip="
              'Total de dias de férias que faltam marcar. Estes dias deverão ser gozados antes do dia 30 de abril do ano seguinte.'
            "
          >
            Por Marcar
          </span>
          <span class="count vacations-unmarked">
            {{ vacationsStatistics.authorized - vacationsStatistics.marked }}
          </span>
        </div>
      </div>
      <div class="p-col-12 p-lg-2">
        <div class="card summary p-shadow-8">
          <span class="title" v-tooltip="'Total de dias de férias aprovados'">
            Aprovados
          </span>
          <span class="count vacations-aproved">
            {{ vacationsStatistics.aproved }}
          </span>
        </div>
      </div>
      <div class="p-col-12 p-lg-2">
        <div class="card summary p-shadow-8">
          <span
            class="title"
            v-tooltip="'Total de dias de férias que faltam aprovar'"
          >
            Por Aprovar
          </span>
          <span class="count vacations-unapproved">
            {{ vacationsStatistics.unapproved }}
          </span>
        </div>
      </div>
      <!-- <div class="p-col-12 p-lg-2">
        <div class="card summary p-shadow-8">
          <span
            class="title"
            v-tooltip="
              'Total de dias de pedidos de alteração de férias que faltam aprovar'
            "
          >
            Alterações
          </span>
          <span class="count vacations-changed">{{
            vacationsStatistics.changed
          }}</span>
        </div>
      </div> -->
    </div>
    <Divider />
    <div class="p-field p-grid">
      <div class="p-col-12 p-md-3 p-mt-1">
        <label for="year-selector">Ano</label>
        <InputNumber
          id="year-selector"
          name="year-selector"
          :value="selectedYear"
          class="p-col-10"
          showButtons
          buttonLayout="horizontal"
          incrementButtonIcon="pi pi-plus"
          decrementButtonIcon="pi pi-minus"
          :step="1"
          :min="currentYear - 10"
          :max="currentYear"
          :useGrouping="false"
          @input="yearChanged"
        />
      </div>
      <div class="p-col-12 p-md-3 p-mt-1">
        <Button
          label="Calendário"
          icon="pi pi-calendar"
          style="width: 100%"
          class="p-button-outlined"
          @click="toogleToCalendar"
          v-if="showList"
        />
        <Button
          label="Férias Marcadas"
          icon="pi pi-slack"
          class="p-button-outlined"
          style="width: 100%"
          v-if="showCalendar"
          @click="toogleToList"
        />
      </div>
    </div>
    <Divider />

    <VacationsList
      v-bind:vacationsStatistics="vacationsStatistics"
      v-bind:selectedYear="selectedYear"
      v-if="showList"
    />
    <VacationsCalendar v-if="showCalendar" v-bind:selectedYear="selectedYear" />
  </div>
</template>

<script>
import VacationsCalendar from "./VacationsCalendar.vue";
import VacationsList from "./VacationsList";
export default {
  name: "Vacations",
  components: {
    VacationsCalendar: VacationsCalendar,
    VacationsList: VacationsList,
  },
  data() {
    return {
      vacationsStatistics: {
        authorized: 22,
        marked: 0,
        aproved: 0,
        unapproved: 0,
        changed: 0,
      },
      selectedYear: new Date().getFullYear() - 1,
      currentYear: new Date().getFullYear(),
      showList: true,
      showCalendar: false,
    };
  },
  methods: {
    yearChanged(year) {
      this.selectedYear = year;
    },
    toogleToList() {
      this.showList = true;
      this.showCalendar = false;
    },
    toogleToCalendar() {
      this.showList = false;
      this.showCalendar = true;
    },
  },
};
</script>
<style>
.count {
  padding: 0px 14px !important;
}
.vacations-total {
  background-color: #20d077;
}
.vacations-marked {
  background-color: #007be5;
}
.vacations-unmarked {
  background-color: #00448f;
}
.vacations-aproved {
  background-color: teal;
}
.vacations-unapproved {
  background-color: #c4a041;
}
.vacations-changed {
  background-color: #ffa726;
}
#year-selector {
  width: 60px;
}
.dashboard .summary .title {
  font-size: 15px;
}
</style>
