var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-mt-2"},[_c('div',{staticClass:"p-d-flex p-jc-center"},[_c('v-calendar',{ref:"calendar",attrs:{"columns":_vm.$screens({ sm: 1, md: 3, lg: 3, xl: 4 }),"rows":_vm.$screens({ sm: 12, md: 4, lg: 4, xl: 3 }),"attributes":_vm.attributes,"from-page":_vm.startMonth,"to-page":_vm.startMonth},on:{"update:from-page":_vm.iChanged}})],1),_c('div',{class:[
    { 'middle': !_vm.$isMobile() },
     'p-col-12',
     'p-md-12'
     ]},[_c('div',{class:[
    { 'middle': !_vm.$isMobile() },
     'p-col-12',
     'p-md-1'
     ]},[_vm._m(0)]),_c('div',{class:[
    { 'middle': !_vm.$isMobile() },
     'p-col-12',
     'p-md-2'
     ]},[_c('div',{staticClass:"dot vacation-approve"}),_vm._v(" Aprovadas ")]),_c('div',{class:[
    { 'middle': !_vm.$isMobile() },
     'p-col-12',
     'p-md-2'
     ]},[_c('div',{staticClass:"dot vacation-not-approve"}),_vm._v(" Por Aprovar ")]),_c('div',{class:[
    { 'middle': !_vm.$isMobile() },
     'p-col-12',
     'p-md-2'
     ]},[_c('div',{staticClass:"dot holidays"}),_vm._v(" Feriados ")]),_c('div',{class:[
    { 'middle': !_vm.$isMobile() },
     'p-col-12',
     'p-md-2'
     ]},[_c('div',{staticClass:"dot weekdays"}),_vm._v(" Fim de Semana ")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b',[_vm._v("Legenda:")])])
}]

export { render, staticRenderFns }