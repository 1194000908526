<template>
  <div class="p-mt-2">
    <div class="p-d-flex p-jc-center">
      <v-calendar
        ref="calendar"
        :columns="$screens({ sm: 1, md: 3, lg: 3, xl: 4 })"
        :rows="$screens({ sm: 12, md: 4, lg: 4, xl: 3 })"
        :attributes="attributes"
        :from-page="startMonth"
        :to-page="startMonth"
        @update:from-page="iChanged"
      />
    </div>
    <div
      v-bind:class="[
      { 'middle': !$isMobile() },
       'p-col-12',
       'p-md-12'
       ]"
    >
      <div
      v-bind:class="[
      { 'middle': !$isMobile() },
       'p-col-12',
       'p-md-1'
       ]"
      >
        <div><b>Legenda:</b></div>
      </div>
      <div
      v-bind:class="[
      { 'middle': !$isMobile() },
       'p-col-12',
       'p-md-2'
       ]"
      >
        <div class="dot vacation-approve"></div>
        Aprovadas
      </div>
      <div
      v-bind:class="[
      { 'middle': !$isMobile() },
       'p-col-12',
       'p-md-2'
       ]"
      >
        <div class="dot vacation-not-approve"></div>
        Por Aprovar
      </div>
      <div
      v-bind:class="[
      { 'middle': !$isMobile() },
       'p-col-12',
       'p-md-2'
       ]"
      >
        <div class="dot holidays"></div>
        Feriados
      </div>
      <div
      v-bind:class="[
      { 'middle': !$isMobile() },
       'p-col-12',
       'p-md-2'
       ]"
      >
        <div class="dot weekdays"></div>
        Fim de Semana
      </div>
    </div>
  </div>
</template>

<script>
import holidaysService from "../services/holidays.service";
import vacationsService from "../services/vacations.service";
export default {
  name: "VacationsCalendar",
  props: ["selectedYear"],
  data() {
    return {
      userId: null,
      startMonth: {
        month: 1,
        year: this.selectedYear + 1,
      },
      attributes: [],
    };
  },
  methods: {
    getHolidays(year) {
      return holidaysService
        .getYearHolidays(this.$store.state.auth.user.id, year)
        .then((response) => {
          response.forEach((holiday) => {
            this.attributes.push({
              highlight: "blue",
              dates: new Date(holiday.day),
              order: 10,
            });
          });
        });
    },
    getVacations(year) {
      return vacationsService
        .getVacationsByYear(this.$store.state.auth.user.id, year)
        .then((response) => {
          response.forEach((vacation) => {
            this.attributes.push({
              highlight: vacation.approved ? "teal" : "yellow",
              dates: new Date(vacation.day),
              order: 5,
            });
          });
        });
    },
    getWeekdays() {
      this.attributes = [];
      this.attributes.push({
        key: "weekdays",
        highlight: "gray",
        dates: { weekdays: [1, 7] },
      });
    },
    iChanged(page) {
      this.getWeekdays();
      this.getHolidays(page.year);
      this.getVacations(page.year - 1);
    },
  },
};
</script>
<style>
.dot {
  height: 25px;
  width: 25px;

  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
  margin-right: 3px;
}
.middle {
  display: flex;
  align-items: center;
  justify-content: center;
}
.weekdays {
  background-color: gray;
}
.holidays {
  background-color: #0b5ed7;
}
.vacation-approve {
  background-color: teal;
}
.vacation-not-approve {
  background-color: #c4a041;
}
</style>
